import React, { useEffect, useRef, useState } from "react";
import cv from "@techstark/opencv-js";
import { FaceLandmarker, FilesetResolver } from '@mediapipe/tasks-vision';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsLeftRight, faCamera, faDownload, faMoon, faVideoCamera, faWarning } from "@fortawesome/free-solid-svg-icons";
import * as fr from "../translations/fr.json";
import * as de from "../translations/de.json";
import * as it from "../translations/it.json";
import * as es from "../translations/es.json";

window.cv = cv;

const PUPIL_TO_IRIS_RATIO = 0.30;
const IRIS_CIRCLE_CROP_FACTOR = 0.95;
// const IRIS_DARK_CONTOUR_THICKNESS = 0.7;
const IRIS_DARK_CONTOUR_THICKNESS_RATIO = 0.05;

let faceLandmarker;
let irisColor, irisColorLab;
let alpha;
let video;
let lastVideoTime = -1;
let activeProcessing = true;
let currDensity = null;
let currSelectedColor = null;
let requestAnimationFrameId;
let cap, src, blendedImage;
let pixelSizeX, pixelSizeY;
let adjustColorFrame = 0; // Adjust eyes color only each 50 frames

// Calculate luminosity of an image
function calculateLuminosity(image) {
  const grayMat = new cv.Mat();
  cv.cvtColor(image, grayMat, cv.COLOR_RGB2GRAY);
  const mean = cv.mean(grayMat);
  grayMat.delete();
  return mean[0];
}

function lab2rgb(lab) {
  var y = (lab[0] + 16) / 116,
    x = lab[1] / 500 + y,
    z = y - lab[2] / 200,
    r, g, b;

  x = 0.95047 * ((x * x * x > 0.008856) ? x * x * x : (x - 16 / 116) / 7.787);
  y = 1.00000 * ((y * y * y > 0.008856) ? y * y * y : (y - 16 / 116) / 7.787);
  z = 1.08883 * ((z * z * z > 0.008856) ? z * z * z : (z - 16 / 116) / 7.787);

  r = x * 3.2406 + y * -1.5372 + z * -0.4986;
  g = x * -0.9689 + y * 1.8758 + z * 0.0415;
  b = x * 0.0557 + y * -0.2040 + z * 1.0570;

  r = (r > 0.0031308) ? (1.055 * Math.pow(r, 1 / 2.4) - 0.055) : 12.92 * r;
  g = (g > 0.0031308) ? (1.055 * Math.pow(g, 1 / 2.4) - 0.055) : 12.92 * g;
  b = (b > 0.0031308) ? (1.055 * Math.pow(b, 1 / 2.4) - 0.055) : 12.92 * b;

  return [Math.max(0, Math.min(1, r)) * 255,
  Math.max(0, Math.min(1, g)) * 255,
  Math.max(0, Math.min(1, b)) * 255]
}

// Convert Lab color to RGB color with luminosity adjustment
function cvLabToRgb(labColor, luminosity, irisBrightness) {
  const labAdjusted = [...labColor];

  // Adjust luminosity to prevent going too much in white tones (prevent shift for dark colors)
  const shift = (currSelectedColor == "PARIS_BLUE" || currSelectedColor == "EVERGREEN" || currSelectedColor == "CARRIBEAN_GREEN" || currSelectedColor == "STEEL_BLUE")
    ? 0
    : +15;
  // const shift = +15;
  const labLuminosity = Math.max(10, Math.min(80, (luminosity / 2.55) + shift));
  labAdjusted[0] = labLuminosity; // Set luminosity in the 0-100 range; max 80 / 100

  // labAdjusted[1] = 0.0250*irisBrightness - 4.850;
  // labAdjusted[2] = -0.01625*irisBrightness - 31.34;

  return lab2rgb(labAdjusted);
}

function constrainBoundingRect(rect, imageWidth, imageHeight) {
  // Ensure the top-left corner of the rectangle is within the image boundaries
  rect.x = Math.max(rect.x, 0);
  rect.y = Math.max(rect.y, 0);

  // Ensure the bottom-right corner of the rectangle is within the image boundaries
  rect.width = Math.max(1, Math.min(rect.width, imageWidth - rect.x));
  rect.height = Math.max(1, Math.min(rect.height, imageHeight - rect.y));

  return rect;
}

export default function Simulator({ isBlurred }) {
  const canvasRef = useRef(null);
  const [language, setLanguage] = useState("en");
  const [selectedIrisColorName, setSelectedIrisColorName] = useState(null);
  const [density, setDensity] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [isInPictureMode, setIsInPictureMode] = useState(false);
  const [pictureCountdown, setPictureCountdown] = useState(null);
  const [cameraAccessRefused, setCameraAccessRefused] = useState(false);

  const t = (str) => {
    switch (language) {
      case "fr":
        return fr[str];
      case "de":
        return de[str];
      case "it":
        return it[str];
      case "es":
        return es[str];
      default:
        return str;
    }
  };

  const handleColorChange = (newColor, newDensity) => {
    // Handle case is which only brightness is changed
    if (!newColor && !newDensity) {
      newColor = currSelectedColor;
      newDensity = currDensity;
    }

    if (newColor == selectedIrisColorName && newDensity == density) {
      irisColor = null;
      irisColorLab = null;
      setSelectedIrisColorName(null);
      return;
    }

    currDensity = newDensity;
    setDensity(newDensity);
    // if (newDensity == "LOW") {
    //   alpha = 0.5;
    // } else if (newDensity == "MEDIUM") {
    //   alpha = 0.5;
    // } else {
    //   alpha = 0.8;
    // }
    if (newDensity == "LOW") {
      alpha = 0.14;
    } else if (newDensity == "HIGH") {
      alpha = 0.30;
    } else { // MEDIUM
      alpha = 0.18;
      // alpha = 1;
    }

    currSelectedColor = newColor;
    setSelectedIrisColorName(newColor);
    if (newColor == "EMERALD_GREEN") {
      // irisColor = new cv.Scalar(116, 145, 93, 255); // RGB
      // irisColorLab = [57, -17, 25];
      if (newDensity == "LOW") {
        irisColorLab = [59, -17, 25];
      } else if (newDensity == "MEDIUM") {
        irisColorLab = [62, -18, 24];
      } else if (newDensity == "HIGH") {
        irisColorLab = [62, -18.6, 25.3];
      }
    } else if (newColor == "SNOW_WHITE") {
      // irisColor = new cv.Scalar(200, 200, 200, 255); // RGB
      // irisColorLab = [56, 0, 0];
      if (newDensity == "LOW") {
        irisColorLab = [59, -17, 25];
      } else if (newDensity == "MEDIUM") {
        irisColorLab = [62, -18.6, 25.3];
      }
    } else if (newColor == "HONEY_GOLD") {
      // irisColor = new cv.Scalar(123, 74, 44, 255); // RGB
      irisColorLab = [37, 19, 27];
    } else if (newColor == "PARIS_BLUE") {
      // irisColorLab = [39.67, -0.17, -7.18];
      irisColorLab = [45.3, -5.1, -18.5];
    } else if (newColor == "EVERGREEN") {
      irisColorLab = [47.8, -11.6, 12.1];
    } else if (newColor == "STEEL_BLUE") {
      irisColorLab = [68.1, -0.7, -3.0];
    } else if (newColor == "CARRIBEAN_GREEN") {
      irisColorLab = [69.5, -25.7, -1.4];
    } else { // RIVIERA BLUE (default)
      // irisColor = new cv.Scalar(89, 136, 215, 255); // RGB
      irisColorLab = [56, 2, -46];
      if (newDensity == "LOW") {
        irisColorLab = [63, -3, -32];
      } else if (newDensity == "MEDIUM") {
        irisColorLab = [63, -2, -38];
      } else if (newDensity == "HIGH") {
        irisColorLab = [63, -1, -43];
      }
    }

    adjustColorFrame = 0; // Force adjusting color on next round
  };

  // Initialize the object detector.
  const initializeObjectDetector = async () => {
    cleanupResources();

    const filesetResolver = await FilesetResolver.forVisionTasks("https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm");
    faceLandmarker = await FaceLandmarker.createFromOptions(filesetResolver, {
      baseOptions: {
        modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
      },
      runningMode: "VIDEO", // "IMAGE"
      numFaces: 1
    });

    video = document.getElementById("video");
    navigator.mediaDevices
      .getUserMedia({
        video: {
          // width: { min: 640, ideal: 1280, max: 1280 },
          // height: { min: 360, ideal: 720, max: 720 },
          width: { min: 640, ideal: 1920, max: 1920 },
          height: { min: 360, ideal: 1080, max: 1080 },
        },
        audio: false
      })
      .then((stream) => {
        video.setAttribute('autoplay', '');
        video.setAttribute('muted', '');
        video.setAttribute('playsinline', '');
        video.srcObject = stream;
        video.addEventListener('loadeddata', runDetector);

        // Get video track settings
        const videoTrackSettings = stream.getVideoTracks()[0].getSettings();

        // Calculate pixel size
        pixelSizeX = videoTrackSettings.width / window.innerWidth; // Width in pixels divided by screen width
        pixelSizeY = videoTrackSettings.height / window.innerHeight; // Height in pixels divided by screen height

        console.log("pixelSizeX -> ", pixelSizeX);
        console.log("pixelSizeY -> ", pixelSizeY);

        // // Close the stream
        // stream.getTracks().forEach(track => track.stop());
      })
      .catch((err) => {
        setCameraAccessRefused(true);
        console.error(`An error occurred: ${err}`);
      });
  };

  // Function to cleanup resources
  const cleanupResources = () => {
    console.log("---");
    console.log("cleaning up");
    console.log("---");
    // Stop video stream if it exists
    if (video && video.srcObject) {
      video.srcObject.getTracks().forEach(track => track.stop());
    }

    // Release OpenCV resources
    if (blendedImage) blendedImage.delete();
    if (src) src.delete();
    if (cap) cap.delete();

    // Release any other resources (e.g., timers, event listeners)
    if (requestAnimationFrameId) cancelAnimationFrame(requestAnimationFrameId);
  };

  // Load iris color on page load
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    setLanguage(queryParams.get("language") || "en");
    const queryParamColor = queryParams.get("color") || "RIVIERA_BLUE";
    handleColorChange(queryParamColor, "MEDIUM");
    initializeObjectDetector();

    // return cleanupResources;
  }, []);


  function runDetector() {
    setIsInPictureMode(false);
    canvasRef.current.width = video.videoWidth;
    canvasRef.current.height = video.videoHeight;
    video.height = video.videoHeight; // Mandatory (prevent Mat size issue)
    video.width = video.videoWidth; // Mandatory (prevent Mat size issue)

    console.log(`video size: ${video.videoWidth}x${video.videoHeight}`);
    const outputCanvas = document.getElementById('output_canvas');
    const proximityWarningEl = document.getElementById("proximity-warning");

    setLoaded(true);

    // Now let's start detecting the stream.
    let landmarkerResults;
    cap = new cv.VideoCapture(video);
    src = new cv.Mat(video.videoHeight, video.videoWidth, cv.CV_8UC4);
    blendedImage = new cv.Mat();

    let leftIrisMask, leftIrisContourMask, leftEyeRect;
    let rightIrisMask, rightIrisContourMask, rightEyeRect;

    const detect = async () => {
      const nowInMs = Date.now();
      if (lastVideoTime !== nowInMs) {
        lastVideoTime = video.currentTime;
        if (activeProcessing) {
          // Ensure both operations complete (simultaneously) before proceeding
          cap.read(src);
          landmarkerResults = faceLandmarker.detectForVideo(video, nowInMs);

          // Wait for MediaPipe faceLandmarker detection to complete
          await landmarkerResults;
        }

        // Landmarks application
        if (landmarkerResults?.faceLandmarks?.[0]) { // & irisColor
          const landmarks = landmarkerResults?.faceLandmarks?.[0];
          const LEFT_IRIS = [474, 475, 476, 477]
          const RIGHT_IRIS = [469, 470, 471, 472]
          const LEFT_EYE = [362, 382, 381, 380, 374, 373, 390, 249, 263, 466, 388, 387, 386, 385, 384, 398]
          const RIGHT_EYE = [33, 7, 163, 144, 145, 153, 154, 155, 133, 173, 157, 158, 159, 160, 161, 246]

          const leftIrisLandmarks = LEFT_IRIS.map(i => [landmarks[i].x * canvasRef.current.width, landmarks[i].y * canvasRef.current.height]);
          const rightIrisLandmarks = RIGHT_IRIS.map(i => [landmarks[i].x * canvasRef.current.width, landmarks[i].y * canvasRef.current.height]);
          const leftEyeLandmarks = LEFT_EYE.map(i => [landmarks[i].x * canvasRef.current.width, landmarks[i].y * canvasRef.current.height]);
          const rightEyeLandmarks = RIGHT_EYE.map(i => [landmarks[i].x * canvasRef.current.width, landmarks[i].y * canvasRef.current.height]);

          const promises = [];
          for (let i = 0; i < 2; i++) {
            const promise = new Promise(resolve => {
              setTimeout(() => {
                const irisCoordinates = (i === 0) ? leftIrisLandmarks : rightIrisLandmarks;
                const eyeCoordinates = (i === 0) ? leftEyeLandmarks : rightEyeLandmarks;

                // Extract eye ROI
                //////////////////
                // Get eye rounding rectangle
                const eyePtsMat1 = cv.matFromArray(eyeCoordinates.length, 1, cv.CV_32SC2, eyeCoordinates.flat());
                const eyeRectUnconstrained = cv.boundingRect(eyePtsMat1);
                eyeRectUnconstrained.y -= 5;
                eyeRectUnconstrained.height += 5;
                const eyeRect = constrainBoundingRect(eyeRectUnconstrained, src.cols, src.rows);
                const eyeROI = src.roi(eyeRect);

                const eyeCoordinatesROI = eyeCoordinates.map(coord => [
                  coord[0] - eyeRect.x,
                  coord[1] - eyeRect.y
                ]);
                const irisCoordinatesROI = irisCoordinates.map(coord => [
                  coord[0] - eyeRect.x,
                  coord[1] - eyeRect.y
                ]);

                // Process eye ROI
                //////////////////

                // Create a mask for the iris region
                const irisContour = new cv.MatVector();
                const irisMask = new cv.Mat.zeros(eyeROI.rows, eyeROI.cols, cv.CV_8UC1);
                irisContour.push_back(cv.matFromArray(irisCoordinatesROI.length, 1, cv.CV_32SC2, irisCoordinatesROI.flat()));
                cv.drawContours(irisMask, irisContour, 0, new cv.Scalar(255), cv.FILLED);
                irisContour.delete();
                irisMask.delete();

                // Compute minimum enclosing circle
                const irisPts = irisCoordinatesROI.map(coord => [coord[0], coord[1]]);
                const irisPtsMat = cv.matFromArray(irisPts.length, 1, cv.CV_32SC2, irisPts.flat());
                const irisMinEnclosingCircle = cv.minEnclosingCircle(irisPtsMat);
                irisPtsMat.delete();
                const circle = new cv.Point(irisMinEnclosingCircle.center.x, irisMinEnclosingCircle.center.y);
                const radius = irisMinEnclosingCircle.radius * IRIS_CIRCLE_CROP_FACTOR;

                // Create circular mask
                const circularMask = new cv.Mat.zeros(eyeROI.rows, eyeROI.cols, cv.CV_8UC1);
                cv.circle(circularMask, circle, radius, new cv.Scalar(255), cv.FILLED, cv.LINE_AA);
                // Darker contour mask around the iris
                const expandedContourMask = new cv.Mat.zeros(eyeROI.rows, eyeROI.cols, cv.CV_8UC1);
                // cv.circle(expandedContourMask, circle, radius, new cv.Scalar(255), radius * 0.02, cv.LINE_AA);
                // cv.circle(expandedContourMask, circle, radius, new cv.Scalar(255), IRIS_DARK_CONTOUR_THICKNESS, cv.LINE_AA);
                cv.circle(expandedContourMask, circle, radius, new cv.Scalar(255), radius * IRIS_DARK_CONTOUR_THICKNESS_RATIO, cv.LINE_AA);

                // Get eye rounding rectangle
                const eyePtsMat = cv.matFromArray(eyeCoordinatesROI.length, 1, cv.CV_32SC2, eyeCoordinatesROI.flat());
                // Draw eye mask
                const contoursVect = new cv.MatVector();
                contoursVect.push_back(eyePtsMat);
                const eyelidMask = new cv.Mat.zeros(eyeROI.rows, eyeROI.cols, cv.CV_8UC1);
                cv.drawContours(eyelidMask, contoursVect, 0, new cv.Scalar(255), cv.FILLED);
                eyePtsMat.delete();
                contoursVect.delete();

                // Convert eyeROI to grayscale & measure iris brightness
                // const eyeROIGray = new cv.Mat();
                // cv.cvtColor(eyeROI, eyeROIGray, cv.COLOR_RGBA2GRAY);
                // const irisInEyeROI = new cv.Mat();
                // eyeROIGray.copyTo(irisInEyeROI, circularMask);
                // const irisBrightness = cv.mean(irisInEyeROI)[0];
                // irisInEyeROI.delete();
                // eyeROIGray.delete();

                if (irisColorLab && i == 0 && adjustColorFrame <= 0) {
                  adjustColorFrame = 60; // Adjust eyes color every 60 frames
                  // const luminosity = calculateLuminosity(eyeROI);
                  // irisColor = new cv.Scalar(...cvLabToRgb(irisColorLab, luminosity));

                  try {
                    // const balanceRectZone = new cv.Rect(600, 200, 15, 15);
                    // const leftRectX = Math.max(0, circle.x + radius + 6);
                    // const leftRectY = Math.max(0, circle.y - 1);
                    const balanceRectLeft = new cv.Rect(circle.x + radius + 7, circle.y - 1, 3, 3);
                    
                    // const rightRectX = Math.max(0, circle.x - radius - 6);
                    // const rightRectY = Math.max(0, circle.y - 1);
                    // const balanceRectRight = new cv.Rect(rightRectX, rightRectY, 3, 3);

                    // const luminosityRight = calculateLuminosity(eyeROI.roi(balanceRectRight));
                    const luminosityLeft = calculateLuminosity(eyeROI.roi(balanceRectLeft));
                    // const luminosityZone = calculateLuminosity(src.roi(balanceRectZone));
                    // console.log("luminosity -> ", luminosity / 2.55, "%");
                    // const luminosity = (luminosityRight + luminosityLeft) / 2;
                    const luminosity = luminosityLeft;
                    // console.log("irisBrightness -> ", irisBrightness);
                    irisColor = new cv.Scalar(...cvLabToRgb(irisColorLab, luminosity));
                    
                    // const rectColor = new cv.Scalar(255, 0, 0);
                    // // cv.rectangle(
                    // //   src,
                    // //   {x: balanceRectZone.x, y: balanceRectZone.y},
                    // //   {x: balanceRectZone.x + balanceRectZone.width, y: balanceRectZone.y + balanceRectZone.height},
                    // //   rectColor,
                    // //   2,
                    // //   cv.LINE_AA
                    // // );
                    // cv.rectangle(
                    //   eyeROI,
                    //   {x: balanceRectLeft.x, y: balanceRectLeft.y},
                    //   {x: balanceRectLeft.x + balanceRectLeft.width, y: balanceRectLeft.y + balanceRectLeft.height},
                    //   rectColor,
                    //   1,
                    //   cv.LINE_AA
                    // );
                    // cv.rectangle(
                    //   eyeROI,
                    //   { x: balanceRectRight.x, y: balanceRectRight.y },
                    //   { x: balanceRectRight.x + balanceRectRight.width, y: balanceRectRight.y + balanceRectRight.height },
                    //   rectColor,
                    //   1,
                    //   cv.LINE_AA
                    // );
                  } catch(_) {
                    // console.error(e);
                    // Skip color adjustment if luminosity cannot be measured properly (e.g. out of eye's zone)
                  }


                  // cv.imshow("output_canvas_tmp", eyeROI);
                } else {
                  adjustColorFrame--;
                }

                // Exclude eyelid from circular mask
                cv.bitwise_and(circularMask, eyelidMask, circularMask);
                // Exclude eyelid from contour/border mask
                cv.bitwise_and(expandedContourMask, eyelidMask, expandedContourMask);

                eyelidMask.delete();

                // Free pupil
                cv.circle(circularMask, circle, radius * PUPIL_TO_IRIS_RATIO, new cv.Scalar(0), -1);

                if (i == 0) {
                  leftIrisMask = circularMask;
                  leftIrisContourMask = expandedContourMask;
                  leftEyeRect = eyeRect;
                } else {
                  rightIrisMask = circularMask;
                  rightIrisContourMask = expandedContourMask;
                  rightEyeRect = eyeRect;
                }

                resolve();
              }, 0); // Delay of 0 milliseconds to ensure asynchronous execution
            });
            promises.push(promise);
          }

          // Wait for all promises to resolve
          await Promise.all(promises);

          // Apply color to masks
          if (irisColor && irisColorLab) {
            src.copyTo(blendedImage);

            // For each iris, apply color and blend with source
            const leftColoredIris = new cv.Mat.ones(leftIrisMask.rows, leftIrisMask.cols, src.type());
            leftColoredIris.setTo(irisColor, leftIrisMask);
            leftColoredIris.setTo(new cv.Scalar(0, 0, 0, 255), leftIrisContourMask);
            // cv.GaussianBlur(leftColoredIris, leftColoredIris, new cv.Size(5, 5), 0, 0, cv.BORDER_DEFAULT);
            cv.blur(leftColoredIris, leftColoredIris, new cv.Size(3, 3));
            cv.addWeighted(src.roi(leftEyeRect), 1, leftColoredIris, alpha, 0, blendedImage.roi(leftEyeRect)); // Adjust alpha value as needed
            cv.blur(blendedImage.roi(leftEyeRect), blendedImage.roi(leftEyeRect), new cv.Size(1, 1));
            // cv.GaussianBlur(src.roi(leftEyeRect), src.roi(leftEyeRect), new cv.Size(15, 15), 0, 0, cv.BORDER_DEFAULT);
            leftIrisMask.delete(); // based on circularMask in iteration
            leftIrisContourMask.delete(); // based on circularMask in iteration
            leftColoredIris.delete();

            const rightColoredIris = new cv.Mat.ones(rightIrisMask.rows, rightIrisMask.cols, src.type());
            rightColoredIris.setTo(irisColor, rightIrisMask);
            rightColoredIris.setTo(new cv.Scalar(0, 0, 0, 255), rightIrisContourMask);
            // cv.GaussianBlur(rightColoredIris, rightColoredIris, new cv.Size(5, 5), 0, 0, cv.BORDER_DEFAULT);
            cv.blur(rightColoredIris, rightColoredIris, new cv.Size(3, 3));
            cv.addWeighted(src.roi(rightEyeRect), 1, rightColoredIris, alpha, 0, blendedImage.roi(rightEyeRect)); // Adjust alpha value as needed
            cv.blur(blendedImage.roi(rightEyeRect), blendedImage.roi(rightEyeRect), new cv.Size(1, 1));
            rightIrisMask.delete(); // based on expandedContourMask in iteration
            rightIrisContourMask.delete(); // based on expandedContourMask in iteration
            rightColoredIris.delete();

            // Display blended image
            cv.imshow(outputCanvas, blendedImage);

            // Release memory
            blendedImage.setTo(new cv.Scalar(0));
          } else {
            cv.imshow(outputCanvas, src);
          }
        } else {
          cv.imshow(outputCanvas, src);
        }
      }

      requestAnimationFrameId = requestAnimationFrame(detect);
    }
    detect();

    console.log("after detect !!!");
  };

  const eyeColors = [
    {
      color: "rgb(89, 136, 215)",
      value: "RIVIERA_BLUE",
      label: "Riviera Blue",
    },
    {
      color: "rgb(74, 114, 145)",
      value: "PARIS_BLUE",
      label: "Paris Blue",
    },
    {
      color: "rgb(116, 145, 93)",
      value: "EMERALD_GREEN",
      label: "Emerald Green",
    },
    {
      color: "rgb(95, 120, 88)",
      value: "EVERGREEN",
      label: "Evergreen",
    },
    {
      color: "rgb(91, 186, 173)",
      value: "CARRIBEAN_GREEN",
      label: "Carribean Green",
    },
    {
      color: "rgb(123, 74, 44)",
      value: "HONEY_GOLD",
      label: "Honey Gold",
    },
    {
      color: "rgb(163, 166, 171)",
      value: "STEEL_BLUE",
      label: "Steel Blue",
    },
  ];

  const [showInstructions, setShowInstructions] = useState(true);
  const displayInstructions = () => {
    setShowInstructions(true);
  };
  const dismissInstructions = () => {
    setShowInstructions(false);
  };
  const [showCredits, setShowCredits] = useState(false);
  const displayCredits = () => {
    setShowCredits(true);
  };
  const dismissCredits = () => {
    setShowCredits(false);
  };

  const handleTakePicture = () => {
    setIsInPictureMode(true);
    setPictureCountdown(3);
    let internalCountdown = 3;
    const flashEl = document.querySelector('.flash');
    const countdownInterval = setInterval(() => {
      if (internalCountdown <= 2) {
        flashEl.classList.add('visible');
      }
      if (internalCountdown <= 1) {
        activeProcessing = false;
        setPictureCountdown(null);
        clearInterval(countdownInterval);
        flashEl.classList.remove('visible');
      } else {
        internalCountdown -= 1;
        setPictureCountdown(internalCountdown);
      }
    }, 850);
  };

  const handleGoBackToSimulation = () => {
    activeProcessing = true;
    setIsInPictureMode(false);
  };

  const handleDownloadPicture = () => {
    const colorToStr = {
      "RIVIERA_BLUE": "Riviera Blue",
      "PARIS_BLUE": "Paris Blue",
      "EMERALD_GREEN": "Emerald Green",
      "HONEY_GOLD": "Honey Gold",
      "STEEL_BLUE": "Steel Blue",
      "SNOW_WHITE": "Snow White",
      "EVERGREEN": "Evergreen",
      "CARRIBEAN_GREEN": "Carribean Green",
    };
    const densityToStr = {
      "LOW": t("Low"),
      "MEDIUM": t("Medium"),
      "HIGH": t("High"),
    };

    // Assuming canvasRef.current is your original canvas
    const originalCanvas = canvasRef.current;

    // Create a temporary canvas
    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = originalCanvas.width;
    tempCanvas.height = originalCanvas.height;
    const tempContext = tempCanvas.getContext('2d');

    // Step 1: Copy original canvas to temporary canvas
    tempContext.drawImage(originalCanvas, 0, 0);

    // Step 2: Horizontally flip the temporary canvas
    tempContext.scale(-1, 1);
    tempContext.drawImage(tempCanvas, -tempCanvas.width, 0, tempCanvas.width, tempCanvas.height);

    // Reset transformation for further drawing
    tempContext.setTransform(1, 0, 0, 1, 0, 0);

    // Step 3: Add text
    const fontSize = Math.ceil(0.025 * tempCanvas.height);
    tempContext.font = `${fontSize}px Arial`;
    tempContext.textAlign = 'center';
    tempContext.fillStyle = 'white';
    tempContext.strokeStyle = 'black';
    tempContext.lineWidth = 2;
    const textX = tempCanvas.width / 2;
    const textY = tempCanvas.height - 50;
    const textContent = `${colorToStr[selectedIrisColorName]} — Intensity: ${densityToStr[density]}`;
    tempContext.strokeText(textContent, textX, textY);
    tempContext.fillText(textContent, textX, textY);

    // Load and draw the SVG image
    const logoImage = new Image();
    logoImage.onload = function() {
      // Adjust drawing settings as needed
      const logoWidthToHeightRatio = 2.68;
      const logoWidth = tempCanvas.height * 0.35;
      const logoHeight = logoWidth / logoWidthToHeightRatio;
      tempContext.drawImage(logoImage, tempCanvas.width / 2 - logoWidth / 2, tempCanvas.height - 80 - logoHeight - fontSize, logoWidth, logoHeight);

      // Once everything is drawn, export the canvas
      const filename = `Dr. Brian Boxer Wachler's Simulation - ${colorToStr[selectedIrisColorName]} - ${densityToStr[density]} intensity`;
      if (navigator.canShare) {
        tempCanvas.toBlob(blob => {
          const fileName = `${filename}.png`; // Name of the file to share
          const file = new File([blob], fileName, {type: 'image/png'}); // Create a File object
          const filesArray = [file]; // Array of files to share
          navigator.share({
              files: filesArray,
              title: filename, // Title of the share
              // text: '', // Text to accompany the share
          })
          .then(() => console.log('Share was successful.'))
          .catch((error) => console.log('Sharing failed', error));
        }, 'image/png');
      } else {
          console.log(`Your system doesn't support sharing files.`);
          const image = tempCanvas.toDataURL('image/jpeg');
          const link = document.createElement('a');
          link.download = `${filename}.jpg`;
          link.href = image;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      }
    };
    logoImage.src = '/BWVI_colorlogo.png';
  };

  return (
    <>
      {cameraAccessRefused && <>
        <div className="h-screen flex items-center justify-center p-5">
          <FontAwesomeIcon icon={faWarning} size="2x" className="mr-4" />
          <p className="max-w-screen-sm">
            To use the ColorEyes&trade; simulator, please allow boxerwachler.com to use your camera by refreshing this page and then click Allow.
          </p>
        </div>
      </>}
      {!cameraAccessRefused &&
      <>
        <div className="flash" />
        {!loaded && <h3>{t("Loading")}...</h3>}
        {!isBlurred && !showInstructions && <div className="overlay-logo">
          <img src="/BWVI_colorlogo.png" />
        </div>}
        <div style={{ zIndex: 100, width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          {!showInstructions && <div className="hud-instructions-toggle" onClick={() => displayInstructions()}>?</div>}
          {showInstructions &&
            <div className="hud-instructions" style={{ filter: isBlurred ? "blur(1rem)" : "none" }}>
              {showCredits && <>
                <h2 className="text-center text-xl font-bold mt-2 mb-4">Credits</h2>
                <p className="text-justify">
                  Dr. Brian Boxer Wachler's simulator is a licensed, custom adaptation of <i>Eyes Recoloring Simulator by <a href="https://helvy.ch" target="_blank" style={{ color: "#fff" }}>Helvy</a></i>,
                  which relies on state-of-the-art artificial intelligence technologies and computer vision algorithms to provide real-time simulation for eyes recoloring.
                </p>
                <button type="button" onClick={() => dismissCredits()}>Close</button>
              </>}
              {!showCredits && <>
                <h2 className="text-center text-xl font-bold mt-2 mb-4">Welcome to Dr. Brian Boxer Wachler's ColorEyes&trade; simulator!</h2>
                <p>This simulator gives you a real-time overview of how your eyes could look like with the ColorEyes&trade; simulator.</p>
                <p className="my-4"><strong>{t("How to use it?")}</strong></p>
                <ul className="instructions-list">
                  <li>💡 {t("Ensure you're in a bright environment")} <small>({t("sunlight gives the best results")})</small></li>
                  <li>📸 {t("Keep steady, take a picture and try out all our colors and intensities!")}</li>
                  <li><span className="mx-1">▼</span> Click Intensity button for low, medium or high color intensity.</li>
                </ul>
                <p className="mt-4 text-justify">
                  Actual results may differ from simulations due to healing and other factors.
                </p>
                <button type="button" onClick={() => dismissInstructions()}>Ok</button>
                <a href="#" className="credits-link" onClick={(e) => { e.preventDefault(); displayCredits() }}>Credits</a>
              </>}
            </div>
          }
          <div
            className="hud-messages"
          >
            <div id="low-light-warning" className="message" style={{ display: "none" }}>
              <FontAwesomeIcon icon={faMoon} /> <strong>{t("Low light")}</strong>
              <br />{t("Increase brightness and tap the camera icon to use the built-in flash; or try it in sunlight for the best results!")}
            </div>
            <div id="proximity-warning" className="message" style={{ display: "none" }}>
              <FontAwesomeIcon icon={faArrowsLeftRight} /> <strong>{t("Too close")}</strong>
              <br />{t("Stay ~40cm away from the camera for better results")}
            </div>
          </div>
          {!showInstructions && <>
            <div className="hud-take-picture">
              {!isInPictureMode && <>
                <span className="help-text">Take Picture ▼</span>
                <button type="button" onClick={() => handleTakePicture()}>
                  <FontAwesomeIcon icon={faCamera} size="xl" />
                </button>
              </>}
              {isInPictureMode && <>
                {pictureCountdown > 0 && <p>{pictureCountdown}</p>}
                {!pictureCountdown && <>
                  <span className="help-text">Back Live ▼</span>
                  <button type="button" onClick={() => handleGoBackToSimulation()}>
                    <FontAwesomeIcon icon={faVideoCamera} size="xl" />
                  </button>
                  <button type="button" onClick={() => handleDownloadPicture()}>
                    <FontAwesomeIcon icon={faDownload} size="xl" />
                  </button>
                </>}
              </>}
            </div>
            <div className="hud-bottom">
              <div className="colors-wrapper">
                <div className="left-col">
                  {eyeColors.slice(0, 3).map((color, idx) => (
                    <div
                      key={`color-${color.value}`}
                      className={`color-label ${selectedIrisColorName == color.value ? 'selected' : ''}`}
                      onClick={() => handleColorChange(color.value, density)}
                      aria-selected={selectedIrisColorName == color.value}
                    >
                      <div className="color-image-badge" style={{ border: `8px solid ${color.color}` }} />
                      <span>{color.label}</span>
                    </div>
                  ))}
                  <div className="density-label">
                    <select value={density} onChange={(e) => handleColorChange(selectedIrisColorName, e.target.value)}>
                      <option value="LOW">{t("Intensity:")} {t("Low")}</option>
                      <option value="MEDIUM">{t("Intensity:")} {t("Medium")}</option>
                      <option value="HIGH">{t("Intensity:")} {t("High")}</option>
                    </select>
                  </div>
                </div>
                <div className="right-col">
                  {eyeColors.slice(3, 7).map((color, idx) => (
                    <div
                      key={`color-${color.value}`}
                      className={`color-label ${selectedIrisColorName == color.value ? 'selected' : ''}`}
                      onClick={() => handleColorChange(color.value, density)}
                      aria-selected={selectedIrisColorName == color.value}
                    >
                      <div className="color-image-badge" style={{ border: `8px solid ${color.color}` }} />
                      <span>{color.label}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>}
          {/* <Webcam
            ref={webcamRef}
            onLoadedData={handleVideoLoad}
            style={{ position: "absolute", left: 0, top: 0, width: 0, height: 0, visibility: "hidden" }}
            videoConstraints={videoConstraints}
            screenshotFormat="image/jpeg"
          /> */}
          <video autoPlay muted playsInline id="video" style={{ position: "absolute", zIndex: -1, top: "50%", left: "50%", width: "1px", height: "1px" }} />
          <canvas id="histo-output" style={{ position: "absolute", zIndex: 1000, height: "200px", width: "200px" }} />
          <canvas
            ref={canvasRef}
            className="output_canvas"
            id="output_canvas"
            style={{ display: "block", position: "absolute", zIndex: 100, top: 0, height: "100%", pointerEvents: "none", filter: isBlurred ? "blur(1rem)" : "none" }}
          ></canvas>
        </div>

        <div style={{ position: "relative" }}>
          <canvas
            className="output_canvas"
            id="output_canvas_tmp"
            style={{ display: "none", position: "relative", top: 0, left: 0, background: "grey" }}
          ></canvas>
        </div>
      </>}
    </>
  );
}
